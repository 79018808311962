/* ManageImage.css */

/* Desktop layout */
@media (max-width: 768px) {
   
    .breadcrumb {
      margin-left: 20px;
    }
    .table {
      width: 100%;
      border-collapse: collapse;
    }
    th, td {
      padding: 10px;
      border: 1px solid #ddd;
    }
    .update-form {
 
      margin: 0 auto;
    }
    .table-button{
      margin-top: 10px !important;

    }
  }
  
  /* Tablet layout */
  @media (min-width: 480px) and (max-width: 767px) {
    .table-button{
      margin-top: 10px !important;

    }
    .main {
      max-width: 800px;
      margin: 0 auto;
    }
    .pagetitle {
      display: block;
    }
    .breadcrumb {
      margin-left: 0;
    }
    .table {
      width: 100%;
      border-collapse: collapse;
    }
    th, td {
      padding: 10px;
      border: 1px solid #ddd;
    }
    .update-form {
      width: 70%;
      margin: 0 auto;
    }
  }
  
  /* Mobile layout */
  @media (max-width: 479px) {
    .main {
      max-width: 400px;
      margin: 0 auto;
    }
    .pagetitle {
      display: block;
    }
    .breadcrumb {
      margin-left: 0;
    }
    .table {
      width: 100%;
      border-collapse: collapse;
    }
    th, td {
      padding: 5px;
      border: 1px solid #ddd;
    }
    .update-form {
      width: 90%;
      margin: 0 auto;
    }
    .table-button{
      margin-top: 10px !important;

    }
  }
  