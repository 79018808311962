.months{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 10px;
}

@media (max-width:425px) {
    .months {
        flex-direction: column;
        align-items: start;
        gap: 10px;
    }
    
}