/* Base styles for info boxes */
.info-box {
    background-color: #ffffff; /* White background for boxes */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure the box takes full column height */
    padding: 1rem; /* Padding inside the box */
    box-sizing: border-box; /* Include padding in the height calculation */
  }
  
  .info-box-body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .info-icon {
    font-size: 2rem; /* Icon size */
    color: #007bff; /* Icon color */
    margin: 0 auto; /* Center icon horizontally */
    display: block; /* Block display for proper centering */
  }
  
  .info-title {
    font-size: 1.25rem; /* Title font size */
    font-weight: 600; /* Bold title */
    margin-top: 1rem; /* Space between icon and title */
    text-align: center; /* Center text */
  }
  
  .info-title span {
    color: #6c757d; /* Secondary color for subtitle */
  }
  
  .info-box .d-flex {
    margin-top: auto; /* Push content to the bottom */
    text-align: center; /* Center align text */
  }
  
  .info-box .ps-3 {
    padding-left: 0; /* Reset padding for small screens */
  }
  
  .info-box h6 {
    font-size: 1.5rem; /* Font size for numbers */
    margin: 0; /* Remove margin */
  }
  
  .info-box .text-success {
    color: #28a745; /* Success color */
  }
  
  .info-box .text-muted {
    color: #6c757d; /* Muted color */
  }
  
  .info-box .small {
    font-size: 0.875rem; /* Smaller text */
  }
  
  .info-box .fw-bold {
    font-weight: 700; /* Bold text */
  }
  
  /* Responsive design for different screen sizes */
  @media (max-width: 1200px) {
    .info-box {
      padding: 0.75rem; /* Adjust padding for medium screens */
    }
  
    .info-box h6 {
      font-size: 1.25rem; /* Smaller font size for medium screens */
    }
  
    .info-title {
      font-size: 1.125rem; /* Slightly smaller title for medium screens */
    }
  }
  
  @media (max-width: 992px) {
    .col-xxl-4 {
      flex: 0 0 100%; /* Full width for smaller screens */
      max-width: 100%;
    }
  
    .info-box {
      padding: 0.5rem; /* Reduce padding for smaller screens */
    }
  
    .info-box h6 {
      font-size: 1.125rem; /* Smaller font size for smaller screens */
    }
  
    .info-title {
      font-size: 1rem; /* Adjust title size for smaller screens */
    }
  }
  
  @media (max-width: 576px) {
    .info-box {
      padding: 0.25rem; /* Minimal padding for very small screens */
    }
  
    .info-box h6 {
      font-size: 1rem; /* Further reduce font size for very small screens */
    }
  
    .info-title {
      font-size: 0.875rem; /* Smaller title size for very small screens */
    }
  }
  