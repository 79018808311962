.main {
    padding: 20px;
  }
  
  .pagetitle {
    margin-bottom: 20px;
  }
  
  .pagetitle h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .breadcrumb {
    padding: 10px;
    border-radius: 5px;
  }
  
  .breadcrumb-item {
    font-size: 16px;
  }
  
  .breadcrumb-item a {
    text-decoration: none;
    color: #337ab7;
  }
  
  .breadcrumb-item a:hover {
    color: #23527c;
  }
  
  .container {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  

  .table td {
    border-bottom: 1px solid #ddd;
  }
  
  .update-form {
    width: 100%;
    padding: 20px;
  }
  
  .up-main-image {
    width: 100%;
    padding: 20px;
  }
  
  .up-main-image div {
    margin-bottom: 20px;
  }
  .custom-table td {
    text-align: center;
  }

  .custom-table td span {
  display: inline-block;
    margin: 0 auto;
    text-align: center;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-control:focus {
    border-color: #aaa;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  @media only screen and (max-width: 768px) {
    .container {
      padding: 10px;
    }
  
    .table {
      font-size: 14px;
   
    }

        .custom-table {
        }
  
    .table th, .table td {
      padding: 5px;
    }
  
    .update-form {
      padding: 10px;
    }
  
    .up-main-image {
      padding: 10px;
    }
  
    .form-control {
      padding: 5px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .container {
      padding: 5px;
    }
  
    .table {
      font-size: 12px;
      overflow-x: scroll;

    }
  
    .table th, .table td {
        text-align: center;
      padding: 2px;
    }
  
    .update-form {
      padding: 5px;
    }
  
    .up-main-image {
      padding: 5px;
    }
  
    .form-control {
      padding: 2px;
    }
  }