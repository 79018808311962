.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.not-found-gif {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.not-found-container > h2 {
  font-size: 28px;
  color: #333;
  margin-bottom: 10px;
}

.not-found-container > p {
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
}

.go-home-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.go-home-button:hover {
  background-color: #f5f5f5 ;
  color: #007bff;
  border: 1px solid #007bff;
}

/* Responsive Design */

/* For large desktops (greater than 1200px) */
@media (min-width: 1200px) {
  .not-found-container > h2 {
    font-size: 32px;
  }
  
  .not-found-container > p {
    font-size: 20px;
  }
  
  .go-home-button {
    font-size: 18px;
    padding: 12px 24px;
  }
}

/* For laptops (between 992px and 1200px) */
@media (min-width: 992px) and (max-width: 1199px) {
  .not-found-container > h2 {
    font-size: 30px;
  }
  
  .not-found-container > p {
    font-size: 18px;
  }
  
  .go-home-button {
    font-size: 16px;
    padding: 10px 20px;
  }
}

/* For tablets (between 768px and 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .not-found-container {
    padding: 15px;
  }

  .not-found-container > h2 {
    font-size: 26px;
  }
  
  .not-found-container > p {
    font-size: 16px;
  }
  
  .go-home-button {
    font-size: 15px;
    padding: 8px 18px;
  }
}

/* For phones (less than 768px) */
@media (max-width: 767px) {
  .not-found-container {
    padding: 10px;
  }

  .not-found-container > h2 {
    font-size: 22px;
  }
  
  .not-found-container > p {
    font-size: 14px;
  }
  
  .go-home-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}

  