/* Header.css */

/* Default sidebar visibility for larger screens */
.sidebar {
    display: block;
  }
  
  /* Hide sidebar by default on smaller screens */
  @media (max-width: 1024px) {
    .sidebar {
      display: none;
      width: 250px;
      left: 0;
      top: 0;
      height: 100%;
      z-index: 1000;
      background-color: #fff;
      box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    }
    .show{
        display: block !important;
    }
  
    .sidebar.open {
      display: block;
    }
  
 
  }
  
  /* Adjust the sidebar close button */
  .toggle-sidebar-btn {
    display: none; /* Hide by default on larger screens */
  }

